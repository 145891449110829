/* ----- Color Variable Spec -----
    p -> Primary
    s -> Secondary
    d -> Dark
    l -> Light
    t -> Status
*/
._2kH1_XzDJ39qPSuIMPELFT {
  border-radius: 200px;
  border: 10px solid #ffffff;
  width: 100px;
  height: 100px;
  margin: auto;
  border-color: #fff #fff #fff transparent;
  animation: _2Vhkl6Fg3epu2aABgM-XhV 1.2s linear infinite; }
  ._2kH1_XzDJ39qPSuIMPELFT._2ZsZ1URlSqJxHaC_8dmHJv {
    width: 16px;
    height: 16px;
    border-width: 3px; }
  ._2kH1_XzDJ39qPSuIMPELFT._1Ueo4jkOR_B35qCZMvn8no {
    width: 32px;
    height: 32px;
    border-width: 10px; }
  ._2kH1_XzDJ39qPSuIMPELFT._2VhC8DTeX79wVAgdIpEWHQ {
    border-color: #3779f7 #3779f7 #3779f7 transparent; }
  ._2kH1_XzDJ39qPSuIMPELFT._15EQGGh_2E5DWw9SBOtQ5J {
    display: inline-block; }

@keyframes _2Vhkl6Fg3epu2aABgM-XhV {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
